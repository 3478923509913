import React from 'react'
import SVG from 'react-inlinesvg'
import { Container, Flex, Box, css, Heading } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'

import './styles.css'

// const Wrapper = styled.div`
//   color: ${(props) => (props.dark ? theme.colors.black.base : theme.colors.white.base)};
//   min-height: ${(props) => (props.big ? "400px;" : "100px;")};
//   background-color: ${(props) => (props.dark ? theme.colors.background.black : theme.colors.white.base)};
//   color: ${(props) => (props.dark ? theme.colors.white.base : theme.colors.black.base)};
//   border-color: ${(props) => (props.dark ? theme.colors.white.base : theme.colors.black.base)};
//   position: relative;
//   overflow: hidden;
//   display: flex;
//   flex-direction: column-reverse;

//   align-items: center;
//   margin: 0 auto;
//   @media (min-width: ${theme.breakpoints.s}) {
//   }
//   @media (min-width: ${theme.breakpoints.m}) {
//     flex-direction: row;
//     margin: 0 auto 0;
//   }
//   @media (min-width: ${theme.breakpoints.l}) {
//   }

// `

// const Content = styled.div`
//   display: block;
//   margin: 0 auto;
//   text-align: center;
//   width: 100%;
//   @media (min-width: ${theme.breakpoints.s}) {
//   }
//   @media (min-width: ${theme.breakpoints.m}) {
//   }
//   @media (min-width: ${theme.breakpoints.l}) {
//   }
// `

// const Grid = styled.div`
//   margin: 0 auto;
//   width: 100%;
//   max-width: ${theme.layout.base};
// `

const styles = {
  container: {
    // borderTop: '2px solid #000'
  },
  logo: {
    width: [100, 200],
    height: [50, 100]
  },
  header: {
    textAlign: 'center',
    marginTop: '1rem'
  }
}

const BrandsStrip = ({ content }) => {
  const { text, collection, buttons } = content
  const settings = {
    dots: false,
    arrows: false,
    pauseOnHover: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: 'linear',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 400,
        settings: {
          dots: true,
          slidesToShow: 1,
          speed: 6000
        }
      }
    ]
  }
  return (
    <div css={css(styles.container)}>
      <Heading variant='h4' as='div' sx={styles.header}>
        We Accept the Following Insurance Plans.
      </Heading>
      <Slider {...settings} slickPlay>
        {collection?.map(({ text, icon }, index) => (
          <span key={index}>
            <SVG src={icon.src} css={css(styles.logo)} />
          </span>
        ))}
        {/* {brands.map((brand, i) => {
          return (
            <span key={i}>
              <GatsbyImage
                imgStyle={{ height: 'auto' }}
                alt={`${brand.node.name} Logo`}
                title={`${brand.node.name} Logo`}
                image={
                  brand.node.childrenFile[0].childImageSharp.gatsbyImageData
                }
                objectFit='contain'
              />
            </span>
          )
        })} */}
      </Slider>
    </div>
  )
}

export default BrandsStrip
